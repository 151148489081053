<template>
  <div class="accommodation-view tw-mb-10">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "AccommodationView",
  beforeMount() {
    this.$store.commit("meta/setMainContainerPadding", {
      defaultContainerPaddingDisabled: true
    });
  },
  destroyed() {
    this.$store.commit("meta/setMainContainerPadding", {
      defaultContainerPaddingDisabled: false
    });
  }
};
</script>
